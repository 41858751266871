<template>
  <div id="App">
    <Logo></Logo>
    <van-row gutter="20">
      <van-col span="2"></van-col>
      <van-col span="20">
        <van-form @submit="onSubmit">
          <van-field
            v-model="userInfo.zh"
            :name="zh"
            :label="zh"
            :placeholder="zh"
            :rules="[{ required: true, message: '请填写' + zh }]"
          />
          <van-field
            v-model="userPwd"
            :name="mm"
            :label="mm"
            :placeholder="mm"
            type="password"
          />
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit">
              绑定
            </van-button>
          </div>
        </van-form>
      </van-col>
      <van-col span="2"></van-col>
    </van-row>
    <van-row gutter="20">
      <van-col span="2"></van-col>
      <van-col span="20">
        <van-field
          label="绑定后可接收预警信息推送"
          label-width="100%"
          label-align="center"
          size="large"
        />
      </van-col>
      <van-col span="2"></van-col>
    </van-row>
    <van-overlay :show="show">
      <div class="wrapper">
        <van-loading color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>

<style>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>

<script>
import { mapState } from "vuex";
import Logo from "../components/Logo.vue";
import { Dialog } from "vant";
export default {
  data() {
    return {
      userInfo: {
        zh: "",
        cdName: "",
        token: "",
        wechatUserCode: ""
      },
      userPwd: "",
      show: false,
      zh: "账号",
      mm: "密码"
    };
  },
  computed: {
    ...mapState(["weChatAppId", "weChatUrl"])
  },
  created: function() {
    // var ua = navigator.userAgent.toLowerCase();
    // if (ua.match(/MicroMessenger/i) == "micromessenger") {
      // 微信浏览器下
      if (typeof this.$route.query.code === "undefined") {
        window.location.href = `${`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
          this.weChatAppId
        }&redirect_uri=${encodeURI(
          this.weChatUrl
        )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`}`;
      } else {
        this.show = true;
        var that = this;
        that.$axios
          .get("/api/WeChat", {
            params: {
              code: that.$route.query.code
            }
          })
          .then(response => {
            if (response.data.userName != "") {
              that.userInfo.wechatUserCode = response.data.wechatUserCode;
              that.userInfo.cdName = response.data.userName;
              that.userInfo.token = response.data.token;
              that.$store.commit("setUserInfo", that.userInfo);
              that.$router.push("/loginout");
            } else {
              that.userInfo.wechatUserCode = response.data.wechatUserCode;
            }
            that.show = false;
          })
          .catch(error => {
            that.show = false;
            Dialog({ message: error });
          });
      }
    // }
  },
  components: {
    Logo
  },
  methods: {
    onSubmit() {
      var that = this;
      that.$axios
        .get("/api/Auth", {
          params: {
            zh: that.userInfo.zh,
            pwd: that.userPwd,
            wechatUserCode: that.userInfo.wechatUserCode
          }
        })
        .then(response => {
          if (response.data.status == "200") {
            that.userInfo.cdName = response.data.userName;
            that.userInfo.token = response.data.token;
            that.$store.commit("setUserInfo", that.userInfo);
            that.$router.push("/loginout");
          } else {
            Dialog({ message: response.data.msg });
          }
        })
        .catch(error => {
            Dialog({ message: error });
        });
    }
  }
};
</script>
